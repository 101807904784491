body {
  width: 100%;
  margin: 0 auto;
  /* text-align: left; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(249, 250, 253);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.42857em 0;
  overflow: hidden;
}

table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}
a {
  color: blue;
  font-size: 0.75rem;
}
@media (min-width: 965px) {
  body {
    width: 70%;
  }
}
